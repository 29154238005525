export const API_URL = 'https://dev.api.crushedice.biz';
export const WEB_EMAIL = 'support@crushedice.biz';
export const ADDRESS_LOOKUP_URL = 'https://address-lookup.crushedice.biz';
export const ADDRESS_LOOKUP_API_KEY = 'mf9tjSa3SLRqdnwEvPQsL3AuwQ9zdj47WYRnHypekqwmxGg65tbge2uZfcJXMf93MtprrDEF6HuHLPj2sKXMs8tHAprGqgUApxLMT7krurF8B6wukSe5zAtGmpw52pYt';
export const RECAPTCHA_SITE_KEY = '6LdOb68UAAAAAIgrC5vTu1mqvMCeW2QmkfMBS0EN';
export const MAX_UPLOAD_LIMIT_SIZE = '1.5GB';
export const S3_BUCKET_URL = 'https://dev.files.crushedice.biz/';
export const SHOW_DEV_ALERT = false;
export const CLIENT_VERSION = '1.0';
export const GIPHY_API_KEY = 'xxx';
export const GIPHY_RATING = 'G';//P-13 / R